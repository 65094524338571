#logo {
  width: 20%;
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.App-header {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
