input {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  height: 50px;
  width: 85vw;
  border: 1px solid #fd239a;
  border-radius: 50px;
  margin-top: 5%;
  font-size: 1.2em;
  font-family: 'Permanent Marker';
  text-transform: capitalize;
  text-align: center;
}

input:focus {
  outline: none;
}

.pos-rel {
  position: relative;
}

.autoContainer {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  width: 75vw;
  margin-top: 0.5vh;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  padding: 5px;
}

.option,
span {
  font-size: 28px;
  text-transform: capitalize;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.loading-indicator-div {
  margin-top: 10vh;
}

.search-options-list {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-options-list::-webkit-scrollbar {
  display: none;
}

.search-options {
  display: flex;
  align-items: center;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.search-details {
  width: 100%;
}

.search-details h1 {
  padding-left: 1%;
  padding-right: 1%;
  text-align: center;
  font-size: 2em;
}

@media only screen and (min-width: 768px) {
  input {
    width: 500px;
    margin-top: 3vh;
  }

  .autoContainer {
    width: 450px;
  }
}
