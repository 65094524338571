@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700&family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700&family=Permanent+Marker&display=swap);
* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  color: white;
  width: 100%;
  height: 100%;
}

body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: 'Permanent Marker', cursive;
  font-size: 3em;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2.5em;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2em;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.5em;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 350;
  font-size: 1.25em;
}

.arrow-button {
  font-family: 'Permanent Marker', cursive;
  color: #03f7eb;
  font-size: 45px;
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  cursor: pointer;
  display: inline-block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.neon-movie-button {
  display: -webkit-flex;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  -webkit-align-items: center;
          align-items: center;
  line-height: 2em;
  cursor: pointer;
  text-decoration: none;
  color: #03f7eb;
  border: #03f7eb 0.125em solid;
  padding: 0.25em 1em;
  border-radius: 1.5em;
  text-shadow: 0 0 0.7em hsl(0 0% 100% / 2), 0 0 0.7em currentColor;
  box-shadow: inset 0 0 0.5em 0 #03f7eb, 0 0 1em 0 #03f7eb;
  position: relative;
  transition: background-color 100ms linear;
  background-color: black;
}

.neon-movie-button::before {
  content: '';
  position: absolute;
  background: #03f7eb;
  top: 110%;
  left: 0;
  width: 100%;
  height: 65%;
  -webkit-transform: perspective(1em) rotatex(40deg) translateZ(0em) scale(1, 0.5);
          transform: perspective(1em) rotatex(40deg) translateZ(0em) scale(1, 0.5);
  -webkit-filter: blur(0.6em);
          filter: blur(0.6em);
  opacity: 0.7;
}

.neon-movie-button:active,
.neon-movie-button:focus {
  background: #03f7eb;
  color: #12615c;
  text-shadow: none;
}

.neon-movie-button::after {
  content: '';
  position: absolute;
  border-radius: 1.5em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 1em 0.5em #03f7eb;
  opacity: 0;
  transition: opacity 100ms linear;
}
.neon-movie-button:active::before {
  opacity: 1.5;
}
.neon-movie-button:active::after {
  opacity: 1;
}

.neon-tv-button {
  display: -webkit-flex;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  -webkit-align-items: center;
          align-items: center;
  line-height: 2em;
  cursor: pointer;
  text-decoration: none;
  color: #b553f5;
  border: #b553f5 0.125em solid;
  padding: 0.25em 1em;
  border-radius: 1.5em;
  text-shadow: 0 0 0.7em hsl(0 0% 100% / 2), 0 0 0.7em currentColor;
  box-shadow: inset 0 0 0.5em 0 #b553f5, 0 0 1em 0 #b553f5;
  position: relative;
  transition: background-color 100ms linear;
  background-color: black;
}

.neon-tv-button::before {
  content: '';
  position: absolute;
  background: #b553f5;
  top: 110%;
  left: 0;
  width: 100%;
  height: 65%;
  -webkit-transform: perspective(1em) rotatex(40deg) translateZ(0em) scale(1, 0.5);
          transform: perspective(1em) rotatex(40deg) translateZ(0em) scale(1, 0.5);
  -webkit-filter: blur(0.6em);
          filter: blur(0.6em);
  opacity: 0.7;
}

.neon-tv-button:active,
.neon-tv-button:focus {
  background: #b553f5;
  color: #603080;
  text-shadow: none;
}

.neon-tv-button::after {
  content: '';
  position: absolute;
  border-radius: 1.5em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 1em 0.5em #b553f5;
  opacity: 0;
  transition: opacity 100ms linear;
}
.neon-tv-button:active::before {
  opacity: 1.5;
}
.neon-tv-button:active::after {
  opacity: 1;
}

.neon-list-button {
  display: -webkit-flex;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  -webkit-align-items: center;
          align-items: center;
  line-height: 2em;
  cursor: pointer;
  text-decoration: none;
  color: #ec5f88;
  border: #ec5f88 0.125em solid;
  padding: 0.25em 1em;
  border-radius: 1.5em;
  text-shadow: 0 0 0.7em hsl(0 0% 100% / 2), 0 0 0.7em currentColor;
  box-shadow: inset 0 0 0.5em 0 #ec5f88, 0 0 1em 0 #ec5f88;
  position: relative;
  transition: background-color 100ms linear;
  background-color: black;
}

.neon-list-button::before {
  content: '';
  position: absolute;
  background: #ec5f88;
  top: 110%;
  left: 0;
  width: 100%;
  height: 65%;
  -webkit-transform: perspective(1em) rotatex(40deg) translateZ(0em) scale(1, 0.5);
          transform: perspective(1em) rotatex(40deg) translateZ(0em) scale(1, 0.5);
  -webkit-filter: blur(0.6em);
          filter: blur(0.6em);
  opacity: 0.7;
}

.neon-list-button:active,
.neon-list-button:focus {
  background: #ec5f88;
  color: #8e3850;
  text-shadow: none;
}

.neon-list-button::after {
  content: '';
  position: absolute;
  border-radius: 1.5em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 1em 0.5em #ec5f88;
  opacity: 0;
  transition: opacity 100ms linear;
}
.neon-list-button:active::before {
  opacity: 1.5;
}
.neon-list-button:active::after {
  opacity: 1;
}

#logo {
  width: 20%;
}

.App {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.App-header {
  padding-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.filter-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.filter-bar h1 {
  font-size: 54px;
  margin-top: 25px;
  margin-bottom: 0px;
}

.filter-bar p {
  margin-top: 5px;
}

.buttons-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-top: 5%;
  margin-bottom: 8%;
  width: 100%;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .filter-bar h1 {
    font-size: 70px;
  }

  .buttons-div {
    margin-top: 5vh;
  }
}

input {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  height: 50px;
  width: 85vw;
  border: 1px solid #fd239a;
  border-radius: 50px;
  margin-top: 5%;
  font-size: 1.2em;
  font-family: 'Permanent Marker';
  text-transform: capitalize;
  text-align: center;
}

input:focus {
  outline: none;
}

.pos-rel {
  position: relative;
}

.autoContainer {
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  width: 75vw;
  margin-top: 0.5vh;
}

.option {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 500px;
  padding: 5px;
}

.option,
span {
  font-size: 28px;
  text-transform: capitalize;
}

.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.flex-column {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.loading-indicator-div {
  margin-top: 10vh;
}

.search-options-list {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-options-list::-webkit-scrollbar {
  display: none;
}

.search-options {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.search-details {
  width: 100%;
}

.search-details h1 {
  padding-left: 1%;
  padding-right: 1%;
  text-align: center;
  font-size: 2em;
}

@media only screen and (min-width: 768px) {
  input {
    width: 500px;
    margin-top: 3vh;
  }

  .autoContainer {
    width: 450px;
  }
}

.card-page {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  padding-left: 1%;
  padding-right: 1%;
  background-color: rgba(0, 0, 0, 0.455);
}

.swipe-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 1024px;
  margin-top: 5%;
}

.like-button {
  cursor: pointer;
}

.card-page-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.card-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.desktop-button {
  display: none;
}

.mobile-button {
  width: 80px;
  height: 100%;
}

.mobile-like-button {
  width: auto;
  height: 70%;
  cursor: pointer;
  margin-top: 2vh;
  object-fit: contain;
}

.swipe-media-desktop-title-div {
  display: none;
}

.swipe-media-mobile-title-div {
  width: 90vw;
}

.swipe-media-mobile-title {
  margin: 3% 0%;
  line-height: 120%;
}

.swipe-button-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 10vh;
}

.card-page-img img {
  width: 80vw;
}

.info-button-div {
  height: 10vh;
}

@media only screen and (min-width: 768px) {
  .desktop-button {
    display: inline-block;
    width: 15%;
  }

  .desktop-like-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: contain;
  }

  .mobile-button {
    display: none;
  }

  /*.button-div {
    display: flex;
    max-width: 1024px;
    justify-content: space-around;
    margin-top: 5%;
  }*/

  .card-page-img img {
    width: 100%;
  }

  .swipe-media-mobile-title-div {
    display: none;
  }

  .swipe-media-desktop-title-div {
    display: inline-block;
    width: 100%;
    max-width: 600px;
  }

  .swipe-media-desktop-title {
    margin-top: 0px;
  }
}

.media-info {
  margin-left: 5%;
  margin-right: 5%;
}

.card-details-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 5%;
}

.card-details {
  width: 100%;
  max-width: 1024px;
  background-color: rgba(0, 0, 0, 0.455);
}

#media-title {
  margin-top: 5%;
  margin-bottom: 5%;
}

.media-info-image {
  width: 100%;
  margin-top: 30px;
}

.media-detail-poster {
  display: none;
}

.media-info-metadata {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.media-rating-trailer {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 3%;
  margin-bottom: 10%;
}

.media-rating-trailer h4 {
  margin-bottom: 25px;
}

#media-overview-section {
  text-align: start;
}

#overview {
  margin-top: 5%;
}

.genres {
  word-wrap: break-word;
  margin: 0%;
}

.tagline {
  text-align: center;
  font-style: italic;
  margin-top: 10%;
  margin-bottom: 5%;
}

#director {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 5%;
}

#slide-show {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 5%;
}
.cast {
  margin-top: 10%;
}

.cast-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
}

#cast-title {
  text-align: start;
}

.slide-show-button {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid black;
}

.streaming-options {
  margin-top: 5%;
  margin: 5%;
  width: 100%;
}

.stream,
.buy,
.rent {
  margin-top: 5%;
}

.streaming-title {
  text-align: start;
}

.icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 5%;
}

.icon {
  margin-left: 5%;
}

.streaming-provider {
  padding: 0% 7%;
}

.season-list {
  display: none;
}

.cast-list-desktop {
  display: none;
}

.back-button,
.yt-button {
  background-color: rgba(0, 0, 0, 0);
  font-weight: 700;
  width: 35vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #03f7eb;
  border: 2px solid #03f7eb;
  margin-top: 20px;
}

.streaming-options {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 70vw;
}

.icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.cast {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.cast-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.not-visible {
  display: none;
}

@media only screen and (min-width: 768px) {
  .media-info-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .media-info-image {
    margin-top: 30px;
  }
  .media-detail-poster {
    display: inline-block;
  }

  .media-rating-trailer h4 {
    margin-bottom: 25px;
  }

  .season-list {
    display: inline-block;
    width: 100%;
  }

  .card-details {
    width: 100%;
  }

  .season-info-div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .season-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 60%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .selected {
    border: solid 2px #03f7eb;
  }

  .season-number {
    margin-top: 5%;
    margin-bottom: 2%;
  }

  .streaming-providers-list {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .card-details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: auto;
  }

  .icons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .streaming-icon {
    margin: 10px;
  }
  .streaming-options {
    display: -webkit-flex;
    display: flex;
  }

  #slide-show {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 5%;
  }

  .cast-name {
    word-break: break-all;
  }

  .season-info p {
    text-align: start;
  }

  .cast-list-mobile {
    display: none;
  }

  .cast-list-desktop {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .back-button,
  .yt-button {
    width: auto;
  }
}

.media-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  border: 3px solid #03f7eb;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 4%;
  border-radius: 8px;
  width: 100%;
  height: 15vh;
}

.media-title h1 {
  font-size: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-title {
  max-width: 90%;
}

.button-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
}

.media-info-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 75%;
  margin-left: 1%;
}

.media-image-div {
  height: 100%;
}

.media-image {
  height: 100%;
}

.media-status {
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
  .media-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    border: 3px solid #03f7eb;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 4%;
    border-radius: 8px;
    width: 100%;
    max-width: 1024px;
    height: 15vh;
  }

  .media-title h1 {
    font-size: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .media-title {
    max-width: 75%;
  }

  .button-div {
    width: 75%;
  }

  .media-info-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    width: 75%;
    margin-left: 1%;
  }

  .media-image {
    height: 100%;
  }

  .media-status {
    margin-bottom: 25px;
  }
}

.card-list-page {
  width: 100%;
  max-width: 1024px;
  height: 100%;
}

.nav-button-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  font-weight: 700;
}

.nav-button,
.delete-list-button {
  background-color: rgba(0, 0, 0, 0);
  font-weight: 700;
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.delete-list-button {
  height: 7vh;
  width: -webkit-max-content;
  width: max-content;
  cursor: pointer;
}

.delete-list-button-img {
  height: 100%;
}

.delete-list-button-img img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.nav-button {
  color: #03f7eb;
  border: 2px solid #03f7eb;
  white-space: nowrap;
  text-align: center;
}

.delete-list-button {
  color: #faff7f;
}

.list-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.list-header h4 {
  margin-bottom: 2%;
}

.media-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
}

.card-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.455);
}

@media only screen and (min-width: 768px) {
  .media-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 85%;
    max-width: 1024px;
  }

  .card-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

