.media-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 3px solid #03f7eb;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 4%;
  border-radius: 8px;
  width: 100%;
  height: 15vh;
}

.media-title h1 {
  font-size: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-title {
  max-width: 90%;
}

.button-div {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.media-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-left: 1%;
}

.media-image-div {
  height: 100%;
}

.media-image {
  height: 100%;
}

.media-status {
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
  .media-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 3px solid #03f7eb;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 4%;
    border-radius: 8px;
    width: 100%;
    max-width: 1024px;
    height: 15vh;
  }

  .media-title h1 {
    font-size: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .media-title {
    max-width: 75%;
  }

  .button-div {
    width: 75%;
  }

  .media-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin-left: 1%;
  }

  .media-image {
    height: 100%;
  }

  .media-status {
    margin-bottom: 25px;
  }
}
