.filter-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-bar h1 {
  font-size: 54px;
  margin-top: 25px;
  margin-bottom: 0px;
}

.filter-bar p {
  margin-top: 5px;
}

.buttons-div {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  margin-bottom: 8%;
  width: 100%;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .filter-bar h1 {
    font-size: 70px;
  }

  .buttons-div {
    margin-top: 5vh;
  }
}
