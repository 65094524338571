.media-info {
  margin-left: 5%;
  margin-right: 5%;
}

.card-details-header {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.card-details {
  width: 100%;
  max-width: 1024px;
  background-color: rgba(0, 0, 0, 0.455);
}

#media-title {
  margin-top: 5%;
  margin-bottom: 5%;
}

.media-info-image {
  width: 100%;
  margin-top: 30px;
}

.media-detail-poster {
  display: none;
}

.media-info-metadata {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.media-rating-trailer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 10%;
}

.media-rating-trailer h4 {
  margin-bottom: 25px;
}

#media-overview-section {
  text-align: start;
}

#overview {
  margin-top: 5%;
}

.genres {
  word-wrap: break-word;
  margin: 0%;
}

.tagline {
  text-align: center;
  font-style: italic;
  margin-top: 10%;
  margin-bottom: 5%;
}

#director {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5%;
}

#slide-show {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
}
.cast {
  margin-top: 10%;
}

.cast-header {
  display: flex;
  justify-content: center;
  align-content: center;
}

#cast-title {
  text-align: start;
}

.slide-show-button {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid black;
}

.streaming-options {
  margin-top: 5%;
  margin: 5%;
  width: 100%;
}

.stream,
.buy,
.rent {
  margin-top: 5%;
}

.streaming-title {
  text-align: start;
}

.icons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5%;
}

.icon {
  margin-left: 5%;
}

.streaming-provider {
  padding: 0% 7%;
}

.season-list {
  display: none;
}

.cast-list-desktop {
  display: none;
}

.back-button,
.yt-button {
  background-color: rgba(0, 0, 0, 0);
  font-weight: 700;
  width: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #03f7eb;
  border: 2px solid #03f7eb;
  margin-top: 20px;
}

.streaming-options {
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.icons {
  display: flex;
  justify-content: space-around;
}

.cast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.cast-list {
  display: flex;
  justify-content: center;
}

.not-visible {
  display: none;
}

@media only screen and (min-width: 768px) {
  .media-info-header {
    display: flex;
    align-items: center;
  }

  .media-info-image {
    margin-top: 30px;
  }
  .media-detail-poster {
    display: inline-block;
  }

  .media-rating-trailer h4 {
    margin-bottom: 25px;
  }

  .season-list {
    display: inline-block;
    width: 100%;
  }

  .card-details {
    width: 100%;
  }

  .season-info-div {
    display: flex;
    justify-content: space-between;
  }

  .season-info {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-around;
  }

  .selected {
    border: solid 2px #03f7eb;
  }

  .season-number {
    margin-top: 5%;
    margin-bottom: 2%;
  }

  .streaming-providers-list {
    display: flex;
    justify-content: center;
  }

  .card-details {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: auto;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .streaming-icon {
    margin: 10px;
  }
  .streaming-options {
    display: flex;
  }

  #slide-show {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
  }

  .cast-name {
    word-break: break-all;
  }

  .season-info p {
    text-align: start;
  }

  .cast-list-mobile {
    display: none;
  }

  .cast-list-desktop {
    display: flex;
    justify-content: space-between;
  }

  .back-button,
  .yt-button {
    width: auto;
  }
}
